<template>
  <section :class="['container', {'ocultar-overflow-y': siMuestraRutaHija}]">
    <article class="pt-3">
      <div class="vista-encabezado">
        <header class="col titulo h4-responsive text-center text-md-left order-1 order-sm-0">
          Filtros de búsqueda
        </header>
        <div class="col-12 col-sm-auto">
          <mdb-btn
            icon="ban"
            color="secundario"
            class="px-3 ml-5 ml-md-0"
            @click="$router.push('/restricciones-pedido')"
          >
            Restricciones
          </mdb-btn>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4 col-xl-3">
          <mdb-input
            class="my-2"
            v-model="fechaPedido"
            type="month"
            label="Mes"
            outline
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-xl-3">
          <mdb-input
            class="my-2"
            v-model="fechaDel"
            type="date"
            label="Desde"
            outline
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-xl-3">
          <mdb-input
            class="my-2"
            v-model="fechaHasta"
            type="date"
            label="Hasta"
            outline
          />
        </div>
        <div class="col-12 col-xl-3">
          <v-select
            v-model="estatusFiltrar"
            :options="EstatusPedidos"
            :reduce="estatus => estatus.nombre"
            :getOptionLabel="option => option.nombre"
            placeholder="Selecciona estatus"
            multiple
            :searchable="false"
            class="outline-datalist my-2"
          >
            <template #header>
              <label class="datalist-label">
                Estatus del pedido
              </label>
            </template>
            <template #option="{ nombre }">
              {{ nombre }}
            </template>
            <template #no-options v-if="estatusFiltrar && estatusFiltrar.length < 3">
              Selecciona un estatus
            </template>
            <template #no-options v-else>
              No se encontraron resultados para la búsqueda
            </template>
          </v-select>
        </div>
        <FiltrosTipo1
          :zonas="zonasFiltro"
          :tipos-cli="tiposFiltro"
          :conDePago="condicionesPagoSelect"
          :tipPrecio="tipoPrecio"
          :ven="vendedoresFiltro"
          nombre
          @zonasAFiltrar="aplicarFiltrosZonas($event.estados, $event.municipios, $event.parroquias)"
          @tiposClienteAFiltrar="tiposAFiltrar=$event; actualizarPedidos()"
          @vendedorAFiltrar="vendedorAFiltrar=$event; actualizarPedidos()"
          @nombreAFiltrar="nombreAFiltrar=$event; actualizarPedidos()"
          @condiPagoAFiltrar="condiPagoAFiltrar=$event; actualizarPedidos()"
          @tipoPrecioAFiltrar="tipoPrecioFiltrar=$event; actualizarPedidos()"
          @eliminarFiltros="restablecerFiltros"
        />
      </div>
      <div
        v-if="cargando"
        class="mensaje-no-items py-3"
      >
        <LoaderKel />
        <p class="texto mt-2">
          Cargando
        </p>
      </div>
      <ul
        v-else-if="Object.keys(pedidos).length"
        class="table pedidos mb-5"
      >
        <li class="encabezado sticky">
          <header class="h4-responsive">Reporte de pedidos</header>
          <p class="descripcion">Pedidos listados: {{Object.keys(pedidos).length}}</p>
          <div class="thead claro">
            <div class="col-2">
              Día
              <small class="d-sm-block small-block">
                Mes
              </small>
            </div>
            <div class="col">
              Cliente - Sucursal
              <small class="d-sm-block small-block">
                Núm Pedido - Zona
              </small>
            </div>
            <div class="col-12 col-sm-4 text-sm-right">
              Estado
              <small class="d-sm-block small-block">
                Monto
              </small>
            </div>
          </div>
        </li>
        <!-- Mensaje para fecha sin pedidos -->
        <li
          class="contenido cursor-pointer"
          v-for="p in pedidos"
          :key="p.id_ped"
          @click="$router.push(`/pedido/${p.id_ped}`)"
        >
          <div
            class="col-sm-2"
            data-columna="Día / Mes"
          >
            <p>
              {{p.fec_ped.split('-')[2]}}
              <span class="small-block">
                {{mesEnLetra(p.fec_ped)}}
              </span>
            </p>
          </div>
          <div
            class="col"
            data-columna="Cliente - Sucursal / Núm Pedido - Zona"
          >
            <p>
              {{p.nom_emp}} - {{p.nom_suc}}
              <span class="small-block">
                {{p.id_ped}} - {{p.ciu_zon}}
              </span>
            </p>
          </div>
          <div
            class="col-12 col-sm-4 text-sm-right"
            data-columna="Estado / Monto"
          >
            <p>
              <span :class="[asignarColorEstado(p.est_ped), 'font-weight-bold']">
                {{ p.est_ped.slice(0,1).toUpperCase() + p.est_ped.slice(1) }}
              </span>
              <span class="small-block">
                {{p.mon_ped}}
              </span>
            </p>
          </div>
        </li>
      </ul>
      <div
        v-else
        class="mensaje-no-items pb-3"
      >
        <font-awesome-icon
          icon="calendar-times"
          size="5x"
          class="icono"
        />
        <p class="texto">No hay pedidos en el filtro seleccionado</p>
      </div>
    </article>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInRight"
      leave-active-class="animated fadeOutRight"
    >
      <router-view />
    </transition>
  </section>
</template>

<script>
import { mdbInput, mdbBtn } from 'mdbvue'
import { EstatusPedidos } from '@/constantes/pedidos.js'
import { apiPost } from '@/funciones/api.js'
import { asignarColorEstado, comprobarRutaHija } from '@/funciones/funciones.js'
import LoaderKel from '@/components/LoaderKel.vue'
import FiltrosTipo1 from '@/components/FiltrosTipo1.vue'

export default {
  name: 'Pedidos',
  components: {
    mdbInput,
    mdbBtn,
    LoaderKel,
    FiltrosTipo1
  },
  data () {
    return {
      EstatusPedidos,
      asignarColorEstado,
      fechaPedido: new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2),
      pedidos: {},
      fechaDel: '',
      fechaHasta: '',
      mes: {},
      condicionesPagoSelect: [],
      condiPagoAFiltrar: [],
      tipoPrecio: [],
      tipoPrecioFiltrar: [],
      zonasFiltro: [],
      tiposFiltro: [],
      vendedoresFiltro: [],
      vendedorAFiltrar: [],
      tiposAFiltrar: [],
      nombreAFiltrar: '',
      fechaAFiltrar: '',
      estatusFiltrar: '',
      estadosAFiltrar: [],
      municipiosAFiltrar: [],
      parroquiasAFiltrar: [],
      estadoSeleccionado: '',
      municipioSeleccionado: '',
      cargando: false,
      timeout: null
    }
  },
  mounted () {
    this.actualizarPedidos()
    this.cargaInicial()
  },
  watch: {
    fechaPedido () {
      this.fechaDel = ''
      this.fechaHasta = ''
      this.actualizarPedidos()
    },
    fechaHasta () {
      if (!this.fechaHasta) return
      this.actualizarPedidos()
    },
    estatusFiltrar () {
      this.actualizarPedidos()
    }
  },
  computed: {
    siMuestraRutaHija () {
      return comprobarRutaHija(this.$route.path.slice(1).split('/'))
    }
  },
  methods: {
    cargaInicial () {
      apiPost({ s: 'clientes_generalMv' }, '')
        .then((res) => {
          this.zonasFiltro = res.data.zon
          this.tiposFiltro = res.data.tcl
          this.vendedoresFiltro = res.data.ven
          this.condicionesPagoSelect = res.data.cp
          this.tipoPrecio = res.data.tpre
        })
    },
    aplicarFiltrosZonas (estadosFil, municipiosFil, parroquiasFil) {
      this.estadosAFiltrar = []
      this.municipiosAFiltrar = []
      this.parroquiasAFiltrar = []

      estadosFil.forEach(e => {
        this.estadosAFiltrar.push(e.est_zon)
      })
      municipiosFil.forEach(m => {
        this.municipiosAFiltrar.push(m.ciu_zon)
      })
      parroquiasFil.forEach(p => {
        this.parroquiasAFiltrar.push(p.sec_zon)
      })

      this.actualizarPedidos()
    },
    actualizarPedidos () {
      if (this.timeout) { clearTimeout(this.timeout) }
      this.timeout = setTimeout(() => {
        this.pedidos = {}
        this.cargando = true
        let data = this.$route.params.sucursal ? 's=' + this.$route.params.sucursal + '&' : ''
        data += this.nombreAFiltrar ? 'nom=' + this.nombreAFiltrar + '&' : ''
        data += this.vendedorAFiltrar ? 'vendedores=' + this.vendedorAFiltrar + '&' : ''
        data += this.fechaPedido ? 'y=' + this.fechaPedido.split('-')[0] + '&m=' + this.fechaPedido.split('-')[1] + '&' : ''
        data += this.fechaHasta ? 'del=' + this.fechaDel + '&al=' + this.fechaHasta + '&' : ''
        data += this.estadosAFiltrar ? 'estados=' + this.estadosAFiltrar + '&' : ''
        data += this.municipiosAFiltrar ? 'municipios=' + this.municipiosAFiltrar + '&' : ''
        data += this.parroquiasAFiltrar ? 'parroquias=' + this.parroquiasAFiltrar + '&' : ''
        data += this.tiposAFiltrar ? 'tipos=' + this.tiposAFiltrar + '&' : ''
        data += this.condiPagoAFiltrar ? 'condicion=' + this.condiPagoAFiltrar + '&' : ''
        data += this.tipoPrecioFiltrar ? 'precio=' + this.tipoPrecioFiltrar + '&' : ''
        data += this.estatusFiltrar ? 'estatus=' + this.estatusFiltrar + '&' : ''
        apiPost({ s: 'pedMv' }, data)
          .then((r) => {
            this.pedidos = r.data.ele
            this.cargando = false
          })
      }, 500) // delay
    },
    mesEnLetra (fechaPlana) {
      var mess = new Date(fechaPlana.substring(5, 7))
      var opt = { month: 'short' }
      var mesLetras = mess.toLocaleDateString('es-VE', opt)
      return mesLetras
    },
    restablecerFiltros () {
      if (this.$route.params.sucursal) {
        this.$router.push('/pedidos')
      }
      this.fechaDel = ''
      this.fechaHasta = ''
      this.vendedorAFiltrar = []
      this.tiposAFiltrar = []
      this.nombreAFiltrar = ''
      this.fechaAFiltrar = ''
      this.estatusFiltrar = ''
      this.estadosAFiltrar = []
      this.municipiosAFiltrar = []
      this.parroquiasAFiltrar = []
      this.condiPagoAFiltrar = []
      this.tipoPrecioFiltrar = []
      this.actualizarPedidos()
    }
  }
}
</script>

<style lang="scss" scoped>
.table.pedidos {
  margin: auto;

  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ("576px");
}
</style>
